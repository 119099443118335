import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Card, Row, Col, Spin } from 'antd';

const Dashboard = ({ dateRange }) => {
  const [insights, setInsights] = useState([]); // Estado para armazenar os dados dos insights
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [averagePurchaseValue, setAveragePurchaseValue] = useState(0); // Valor médio de `purchase_value`
  const [maxPurchaseValue, setMaxPurchaseValue] = useState(0); // Valor máximo de `purchase_value`

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        setLoading(true);
  
        const localAdAccountId = localStorage.getItem('token');
        const body = dateRange
          ? { startDate: dateRange[0].format('YYYY-MM-DD'), endDate: dateRange[1].format('YYYY-MM-DD') }
          : { filter: 'ultimos_30' };
  
        const response = await fetch(
          `https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`,
          {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localAdAccountId}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }
        );
  
        const data = await response.json();
  
        // Obter todas as datas dentro do intervalo sem problemas de fuso horário
        const startDate = new Date(body.startDate || data[0]?.date_start);
        const endDate = new Date(body.endDate || data[data.length - 1]?.date_start);
        const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  
        const allDays = Array.from({ length: totalDays }, (_, i) => {
          const date = new Date(startDate);
          date.setDate(startDate.getDate() + i); // Incrementa os dias corretamente
          return {
            date: date.toISOString().split('T')[0], // Formata como YYYY-MM-DD
            formattedDate: date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' }), // Formato para o rótulo
            purchase_value: 0,
            spend: 0,
          };
        });
  
        // Mapear dados reais e completar com dias vazios
        const dataWithDays = allDays.map(day => {
          const dayData = data.find(item => item.date_start === day.date);
          return dayData
            ? { 
                ...day,
                ...dayData, 
                purchase_value: parseFloat(dayData.purchase_value || 0), 
                spend: parseFloat(dayData.spend || 0) 
              }
            : day;
        });
  
        setInsights(dataWithDays);
  
        // Calcula a média do `purchase_value`
        const totalPurchaseValue = dataWithDays.reduce((acc, d) => acc + d.purchase_value, 0);
        const avgPurchase = totalPurchaseValue / dataWithDays.length;
        setAveragePurchaseValue(avgPurchase);
  
        // Calcula o valor máximo de `purchase_value` para ajustar o eixo Y
        const maxPurchase = Math.max(...dataWithDays.map(d => d.purchase_value));
        setMaxPurchaseValue(maxPurchase);
  
        setLoading(false); // Para de exibir o carregamento
      } catch (error) {
        console.error('Erro ao buscar dados:', error); // Exibe erros no console
        setLoading(false);
      }
    };
  
    fetchInsights(); // Chama a função para buscar os dados ao carregar o componente
  }, [dateRange]); // Refaz a busca sempre que o intervalo de datas mudar
  

  // Função para formatar os valores em reais
  const formatCurrency = (value) => {
    const numericValue = Number(value);
    return !isNaN(numericValue)
      ? `R$ ${numericValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      : value;
  };

  return (
    <div style={{ padding: '0px' }}>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" /> {/* Exibe o componente de carregamento */}
        </div>
      ) : (
        <Row gutter={16}>
          <Col span={24}>
            <Card style={{ background: 'none', border: 'none' }}>
              <ResponsiveContainer width="100%" height={220}>
                <AreaChart
                  data={insights}
                  margin={{ top: 60, right: 30, left: 20, bottom: 0 }}
                >
                  {/* Definindo o filtro SVG para o efeito neon */}
                  <defs>
                    <filter id="neon" x="-50%" y="-50%" width="200%" height="200%">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="coloredBlur" />
                      <feMerge>
                        <feMergeNode in="coloredBlur" />
                        <feMergeNode in="SourceGraphic" />
                      </feMerge>
                    </filter>
                  </defs>

                  <XAxis dataKey="date" />
                  <YAxis
                    tickFormatter={(value) => formatCurrency(value)}
                    domain={[0, maxPurchaseValue * 1.1]}
                  />
                  <Tooltip formatter={(value) => formatCurrency(value)} />
                  <Area
                    type="monotone"
                    dataKey="purchase_value"
                    stroke="#8884d8"
                    fill="#4a148c"
                    animationDuration={2000}
                    strokeWidth={2}
                    style={{ filter: 'url(#neon)' }}
                  />
                  <Area
                    type="monotone"
                    dataKey="spend"
                    stroke="#fe8400"
                    fill="#f8b100"
                    animationDuration={2000}
                    strokeWidth={2}
                    style={{ filter: 'url(#neon)' }}
                  />
                  <ReferenceLine
                    y={averagePurchaseValue}
                    label={`Média de Vendas: ${formatCurrency(averagePurchaseValue)}`}
                    stroke="#cf14b7"
                    strokeDasharray="3 3"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Dashboard;
