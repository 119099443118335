import React, { useState, useEffect } from 'react';

const Funil = () => {
    const [insights, setInsights] = useState([]); // Estado para armazenar os dados
    const [loading, setLoading] = useState(true); // Estado para controle de carregamento
    const [error, setError] = useState(null); // Estado para erros

    useEffect(() => {
        const fetchInsights = async () => {
            try {
                const localAdAccountId = localStorage.getItem('token');

                if (!localAdAccountId) {
                    throw new Error('ID da conta de anúncios não encontrado no localStorage');
                }

                // Faz a requisição para a API utilizando o ID da conta de anúncios
                const response = await fetch(
                    `https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`
                );

                if (!response.ok) {
                    throw new Error('Erro ao buscar dados da API');
                }

                const data = await response.json();
                setInsights(data); // Armazena os dados diretamente
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
                setError(error.message); // Armazena o erro para exibição
            } finally {
                setLoading(false);
            }
        };

        fetchInsights();
    }, []);

    if (loading) {
        return <div>Carregando dados do funil...</div>;
    }

    if (error) {
        return <div>Erro: {error}</div>;
    }

    if (insights.length === 0) {
        return <div>Nenhum dado disponível.</div>;
    }

    // Função para calcular as larguras relativas
    const calculateWidth = (value, maxValue) => {
        const percentage = (value / maxValue) * 100;
        return `${percentage}%`;
    };

    // Função para calcular porcentagem de conversão entre duas etapas
    const calculatePercentage = (current, previous) => {
        if (previous === 0) return '0%';
        return `${((current / previous) * 100).toFixed(2)}%`;
    };

    // Pegando os valores totais das etapas
    const totalViews = insights.reduce((acc, item) => acc + parseInt(item.landing_page_view || 0), 0);
    const totalAddToCart = insights.reduce((acc, item) => acc + parseInt(item.add_to_cart || 0), 0);
    const totalFinalizations = insights.reduce((acc, item) => acc + parseInt(item.finalization_of_purchase || 0), 0);
    const totalPurchases = insights.reduce((acc, item) => acc + parseInt(item.purchase || 0), 0);

    // Calculando porcentagens de quebra/conversão
    const addToCartPercentage = calculatePercentage(totalAddToCart, totalViews); // Adições ao carrinho / Visualizações da página
    const finalizationPercentage = calculatePercentage(totalFinalizations, totalAddToCart); // Finalizações / Adições ao carrinho
    const purchasePercentage = calculatePercentage(totalPurchases, totalFinalizations); // Compras / Finalizações

    // Valor máximo para cálculo relativo
    const maxValue = totalViews;

    // Estilos
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '20px',
        alignItems: 'center', // Centraliza o conteúdo horizontalmente
    };

    const outerContainerStyle = {
        display: 'flex',
        justifyContent: 'center', // Centraliza o conteúdo horizontalmente
        alignItems: 'center', // Centraliza o conteúdo verticalmente
        // backgroundColor: '#121212', // Cor de fundo opcional
        color: '#fff', // Cor do texto padrão
        // minHeight: '100vh',
    };

    const stepStyle = {
        height: '50px',
        color: '#fff',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
    };

    return (
        <div style={outerContainerStyle}>
            <div>

                <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Funil de Conversão</h1>
                <div style={containerStyle}>
                    <span>Visualizações da Página: {totalViews}</span>

                    <div style={{ ...stepStyle, width: '95vw', backgroundColor: '#4CAF50' }}>
                        <span>{totalViews}</span>
                    </div>
                    <span>
                        Adições ao Carrinho: {totalAddToCart} | Ideal: 5%
                    </span>
                    <div
                        style={{
                            ...stepStyle,
                            width: calculateWidth(totalAddToCart, maxValue),
                            backgroundColor: '#FFC107',
                        }}
                    >
                        <span>
                            ({addToCartPercentage})
                        </span>
                    </div>
                    <span>
                        Finalizações de Compras: {totalFinalizations} | Ideal: 50%
                    </span>
                    <div
                        style={{
                            ...stepStyle,
                            width: calculateWidth(totalFinalizations, maxValue),
                            backgroundColor: '#FF9800',
                        }}
                    >
                        <span>
                            ({finalizationPercentage})
                        </span>
                    </div>
                    <span>
                        Compras: {totalPurchases} | Ideal: 80%
                    </span>
                    <div
                        style={{
                            ...stepStyle,
                            width: calculateWidth(totalPurchases, maxValue),
                            backgroundColor: '#F44336',
                        }}
                    >
                        <span>
                            ({purchasePercentage})
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Funil;
