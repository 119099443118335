import React, { useEffect, useState } from 'react';
import { Table, Typography, Spin, Modal, Button, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import * as XLSX from 'xlsx'; // Biblioteca para exportar para Excel

const { TabPane } = Tabs;

const AllConts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [monthlySummary, setMonthlySummary] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenBM = localStorage.getItem('tokenBM');
        if (!tokenBM) throw new Error('TokenBM (adBmCode) não encontrado.');

        const response = await axios.post(
          'https://ads-meta-867395045570.us-central1.run.app/api/ad-accounts',
          { adBmCode: tokenBM }
        );
        console.log('asdsadasdasdasdafasdfdsaf',response.data)
        // const response = await axios.post(
        //   'http://localhost:3001/api/ad-accounts',
        //   { adBmCode: tokenBM }
        // );

        const formattedData = response.data.map(account => ({
          ...account,
          monthly_insights: addMonthlyComparisons(account.monthly_insights),
        }));

        setData(formattedData);
        calculateMonthlySummary(formattedData);
      } catch (error) {
        console.error('Erro ao buscar dados das contas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const addMonthlyComparisons = monthlyInsights => {
    return monthlyInsights.map((insight, index) => {
      if (index === 0) return { ...insight, comparativo: null };

      const prevInsight = monthlyInsights[index - 1];
      const calcComparison = (current, previous) => {
        if (previous === 0) return null;
        const difference = ((current - previous) / previous) * 100;
        return difference.toFixed(1);
      };

      return {
        ...insight,
        comparativo: {
          valor_faturado: calcComparison(insight.valor_faturado, prevInsight.valor_faturado),
          investimento: calcComparison(insight.investimento, prevInsight.investimento),
        },
      };
    });
  };

  const calculateMonthlySummary = data => {
    const monthlyData = {};

    data.forEach(account => {
      account.monthly_insights.forEach(insight => {
        const month = insight.month;

        if (!monthlyData[month]) {
          monthlyData[month] = {
            month,
            valor_faturado: 0,
            investimento: 0,
            impressions: 0,
            reach: 0,
            ctr: { total: 0, count: 0 },
            cpm: { total: 0, count: 0 },
            cpc: { total: 0, count: 0 },
            cps: { total: 0, count: 0 },
          };
        }

        monthlyData[month].valor_faturado += parseFloat(insight.valor_faturado) || 0;
        monthlyData[month].investimento += parseFloat(insight.investimento) || 0;
        monthlyData[month].impressions += parseInt(insight.impressions) || 0;
        monthlyData[month].reach += parseInt(insight.reach) || 0;

        if (insight.ctr) {
          monthlyData[month].ctr.total += parseFloat(insight.ctr);
          monthlyData[month].ctr.count++;
        }
        if (insight.cpm) {
          monthlyData[month].cpm.total += parseFloat(insight.cpm);
          monthlyData[month].cpm.count++;
        }
        if (insight.cpc) {
          monthlyData[month].cpc.total += parseFloat(insight.cpc);
          monthlyData[month].cpc.count++;
        }
        if (insight.cps) {
          monthlyData[month].cps.total += parseFloat(insight.cps);
          monthlyData[month].cps.count++;
        }
      });
    });

    const summaryWithComparisons = Object.values(monthlyData).map((item, index, arr) => {
      const prevItem = index > 0 ? arr[index - 1] : null;

      const calcComparison = (current, previous) => {
        if (previous === 0 || previous === null) return null;
        const difference = ((current - previous) / previous) * 100;
        return difference.toFixed(1);
      };

      return {
        month: item.month,
        valor_faturado: item.valor_faturado.toFixed(2),
        investimento: item.investimento.toFixed(2),
        impressions: item.impressions,
        reach: item.reach,
        ctr: item.ctr.count ? (item.ctr.total / item.ctr.count).toFixed(2) : 0,
        cpm: item.cpm.count ? (item.cpm.total / item.cpm.count).toFixed(2) : 0,
        cpc: item.cpc.count ? (item.cpc.total / item.cpc.count).toFixed(2) : 0,
        cps: item.cps.count ? (item.cps.total / item.cps.count).toFixed(2) : 0,
        comparativo: prevItem ? {
          valor_faturado: calcComparison(item.valor_faturado, prevItem.valor_faturado),
          investimento: calcComparison(item.investimento, prevItem.investimento),
        } : null,
      };
    });

    const orderedSummary = summaryWithComparisons.sort((a, b) => {
      const [monthA, yearA] = a.month.split('/').map(Number);
      const [monthB, yearB] = b.month.split('/').map(Number);
      return yearA === yearB ? monthA - monthB : yearA - yearB;
    });

    setMonthlySummary(orderedSummary);
  };

  const exportToExcel = (data, filename) => {
    const formattedData = data.map(item => ({
      month: item.month,
      valor_faturado: `R$ ${parseFloat(item.valor_faturado).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
      })}`,
      investimento: `R$ ${parseFloat(item.investimento).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
      })}`,
      impressions: item.impressions,
      reach: item.reach,
      ctr: `${parseFloat(item.ctr).toFixed(2)}%`,
      cpm: `R$ ${parseFloat(item.cpm).toFixed(2)}`,
      cpc: `R$ ${parseFloat(item.cpc).toFixed(2)}`,
      cps: `R$ ${parseFloat(item.cps).toFixed(2)}`,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  const formatCurrency = value =>
    `R$ ${parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
  const formatPercentage = value => `${parseFloat(value).toFixed(2)}%`;

  const renderWithComparison = (value, comparison) => {
    const isPositive = comparison >= 0;
    const formattedComparison = comparison !== null ? `${Math.abs(comparison)}%` : '-';
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <span>{formatCurrency(value)}</span>
        {comparison !== null && (
          <span style={{ color: isPositive ? '#4CAF50' : '#F44336', fontSize: '0.85em' }}>
            {isPositive ? '↑' : '↓'} {formattedComparison}
          </span>
        )}
      </div>
    );
  };

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  if (loading) return <Spin tip="Carregando dados..." />;

  return (
    <div style={{ padding: '20px' }}>
      <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        size="large"
        style={{
          position: 'fixed',
          bottom: 74,
          right: 24,
          zIndex: 1000,
        }}
        onClick={showModal}
      />

      <Modal
        title="Resumo Mensal das Contas"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Tabs defaultActiveKey="resumo">
          <TabPane tab="Resumo Consolidado" key="resumo">
            <Button
              type="primary"
              onClick={() => exportToExcel(monthlySummary, 'Resumo_Consolidado')}
              style={{ marginBottom: '16px' }}
            >
              Exportar Resumo Consolidado para Excel
            </Button>
            <Table dataSource={monthlySummary} pagination={false} bordered rowKey="month">
              <Table.Column title="Mês" dataIndex="month" key="month" />
              <Table.Column
                title="Valor Faturado"
                key="valor_faturado"
                render={(text, record) =>
                  renderWithComparison(
                    record.valor_faturado,
                    record.comparativo ? record.comparativo.valor_faturado : null
                  )
                }
              />
              <Table.Column
                title="Investimento"
                key="investimento"
                render={(text, record) =>
                  renderWithComparison(
                    record.investimento,
                    record.comparativo ? record.comparativo.investimento : null
                  )
                }
              />
              <Table.Column title="Impressões" dataIndex="impressions" key="impressions" />
              <Table.Column title="Alcance" dataIndex="reach" key="reach" />
              <Table.Column title="CTR (%)" dataIndex="ctr" key="ctr" render={formatPercentage} />
              <Table.Column title="CPM (R$)" dataIndex="cpm" key="cpm" render={formatCurrency} />
              <Table.Column title="CPC (R$)" dataIndex="cpc" key="cpc" render={formatCurrency} />
              <Table.Column title="CPS (R$)" dataIndex="cps" key="cps" render={formatCurrency} />
            </Table>
          </TabPane>
          {data.map(account => (
            <TabPane tab={account.name} key={account.id}>
              <Button
                type="primary"
                onClick={() => exportToExcel(account.monthly_insights, account.name)}
                style={{ marginBottom: '16px' }}
              >
                Exportar {account.name} para Excel
              </Button>
              <Table
                dataSource={account.monthly_insights}
                rowKey="month"
                pagination={false}
                bordered
                title={() => (
                  <div>
                    <strong>Conta ID:</strong> {account.id} | <strong>Moeda:</strong> {account.currency} |{' '}
                    <strong>Saldo:</strong> R${(account.balance / 100).toFixed(2)}
                  </div>
                )}
              >
                <Table.Column title="Mês" dataIndex="month" key="month" />
                <Table.Column
                  title="Valor Faturado"
                  key="valor_faturado"
                  render={(text, record) =>
                    renderWithComparison(
                      record.valor_faturado,
                      record.comparativo ? record.comparativo.valor_faturado : null
                    )
                  }
                />
                <Table.Column
                  title="Investimento"
                  key="investimento"
                  render={(text, record) =>
                    renderWithComparison(
                      record.investimento,
                      record.comparativo ? record.comparativo.investimento : null
                    )
                  }
                />
                <Table.Column title="Impressões" dataIndex="impressions" key="impressions" />
                <Table.Column title="Alcance" dataIndex="reach" key="reach" />
                <Table.Column title="CTR (%)" dataIndex="ctr" key="ctr" render={formatPercentage} />
                <Table.Column title="CPM (R$)" dataIndex="cpm" key="cpm" render={formatCurrency} />
                <Table.Column title="CPC (R$)" dataIndex="cpc" key="cpc" render={formatCurrency} />
                <Table.Column title="CPS (R$)" dataIndex="cps" key="cps" render={formatCurrency} />
              </Table>
            </TabPane>
          ))}
        </Tabs>
      </Modal>
    </div>
  );
};

export default AllConts;
