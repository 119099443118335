import React, { useState, useEffect } from "react";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { initializeApp, getApps } from "firebase/app";
import './NameEmpresa.css'

const firebaseConfig = {
  apiKey: "AIzaSyCboGZey8N5fTPjhzExQ2EdwnMxMcZkyKY",
  authDomain: "login-dashboard-3b427.firebaseapp.com",
  projectId: "login-dashboard-3b427",
  storageBucket: "login-dashboard-3b427.appspot.com",
  messagingSenderId: "1099463922095",
  appId: "1:1099463922095:web:83d57a3ea38cea8e4a5f5a",
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const db = getFirestore(app);

const NameEmpresa = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchName = async () => {
      try {
        const adAccountCode = localStorage.getItem("token");
        console.log("Valor do token do localStorage:", adAccountCode);

        if (!adAccountCode) {
          throw new Error("Token não encontrado no localStorage.");
        }

        // Cria uma consulta para buscar o documento onde o campo adAccountCode corresponde ao valor do token
        const q = query(
          collection(db, "users"),
          where("adAccountCode", "==", adAccountCode)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Pega o primeiro documento que corresponde
          const docData = querySnapshot.docs[0].data();
          console.log("Documento encontrado:", docData);
          setName(docData.name);
        } else {
          throw new Error("Documento não encontrado no Firestore.");
        }
      } catch (err) {
        console.error("Erro ao buscar o documento:", err.message);
        setError(err.message);
      }
    };

    fetchName();
  }, []);

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    <div style={{margin:'30PX',}}>
      <h1>{name}</h1>
    </div>
  );
};

export default NameEmpresa;
