import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Layout, Menu, Spin } from 'antd';
import StatsCardData from './StatsCardsData.js';
import WeeklyStats from './WeeklyStats.js';
import WeeklyConversionStats from './WeeklyConversionStats.js';
import DataTable from './DataTable.js';
import AllConts from './AllConts.js';
import NameEmpresa from './NameEmpresa.js';
import Gender from './gender.js';
import Login from './Login.js';
import DashboardGA4 from './DashboardGA4.js';
import DataTableGA4 from './DataTableGA4.js';
import MobileStatsCardsCard from './MobileStatsCardsCard.js';
import { useMediaQuery } from 'react-responsive'; // Adicionando dependência para media queries
import Funil from './funil.js';

const { Header, Content, Footer } = Layout;

function ProtectedRoute({ children }) {
  const localAdAccountId = localStorage.getItem('token');
  return localAdAccountId ? children : <Navigate to="/login" />;
}

function App() {
  const [selectedMenu, setSelectedMenu] = useState('Meta');
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState(null);

  // Detectar se está em mobile ou desktop
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Mobile se a largura for <= 768px
  const isDesktop = useMediaQuery({ minWidth: 769 }); // Desktop se a largura for >= 769px

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin size="large" />
      </div>
    );
  }

  const renderContent = () => {
    if (selectedMenu === 'Meta') {
      return (
        <div style={{ padding: 10, background: 'none', minHeight: 380 }}>
          {/* Renderiza apenas no mobile */}
          {isMobile && <MobileStatsCardsCard />}
          
          {/* Renderiza apenas no desktop */}
          {isDesktop && (
            <>
              <AllConts />
              <NameEmpresa />
              <StatsCardData dateRange={dateRange} setDateRange={setDateRange} />
              <WeeklyStats dateRange={dateRange} />
              <WeeklyConversionStats dateRange={dateRange} />
              <DataTable dateRange={dateRange} />
              <Funil></Funil>
            </>
          )}
        </div>
      );
    } else if (selectedMenu === 'Calendário estratégico') {
      return (
        <div style={{ padding: 10, background: 'none', minHeight: 380 }}>
          <iframe
            src="https://calendarioumbrella10.web.app/"
            title="Calendário Estratégico"
            style={{
              width: '100%',
              height: '90vh',
              border: 'none',
              borderRadius: '10px',
              overflow: 'hidden',
              minHeight: '600px',
            }}
          ></iframe>
        </div>
      );
    } else if (selectedMenu === 'Google Analitycs') {
      return (
        <div style={{ padding: 10, background: 'none', minHeight: 380 }}>
          <DashboardGA4 />
          <DataTableGA4 />
        </div>
      );
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ background: '#3a0c8d', padding: 0, display: 'flex', alignItems: 'center' }}>
        <div className="logo" style={{ marginLeft: '16px', marginRight: '20px', height: '48px' }}>
          <img
            src="https://umbrellatraffic.com.br/wp-content/uploads/2024/09/logo.webp"
            alt="Logo"
            style={{ height: '100%', width: 'auto', objectFit: 'contain' }}
          />
        </div>
        <Menu
          theme="dark"
          style={{ background: '#3a0c8d', flex: 1, color: 'white' }}
          mode="horizontal"
          defaultSelectedKeys={['Meta']}
          onClick={(e) => setSelectedMenu(e.key)}
        >
          <Menu.Item key="Meta">Facebook</Menu.Item>
          <Menu.Item key="Calendário estratégico">Calendário estratégico</Menu.Item>
          {/* <Menu.Item key="Google Analitycs">Google Analitycs</Menu.Item> */}
        </Menu>
      </Header>
      <Content style={{ padding: '0 10px', marginTop: 0, backgroundColor: '#0f0e1f', color: 'white' }}>
        <Gender />
        {renderContent()}
      </Content>
      <Footer style={{ textAlign: 'center', background: '#3a0c8d', color: 'white' }}>
        Umbrella Traffic technology ©2024 Created by Umbrella Traffic
      </Footer>
    </Layout>
  );
}

export default function MainApp() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><App /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}
