import React, { useState, useEffect } from "react";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { initializeApp, getApps } from "firebase/app";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCboGZey8N5fTPjhzExQ2EdwnMxMcZkyKY",
  authDomain: "login-dashboard-3b427.firebaseapp.com",
  projectId: "login-dashboard-3b427",
  storageBucket: "login-dashboard-3b427.appspot.com",
  messagingSenderId: "1099463922095",
  appId: "1:1099463922095:web:83d57a3ea38cea8e4a5f5a",
};

// Inicializa o Firebase
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const db = getFirestore(app);

const Gender = () => {
  const [lookerUrl, setLookerUrl] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLookerUrl = async () => {
      try {
        const adAccountCode = localStorage.getItem("token");
        if (!adAccountCode) {
          throw new Error("Token não encontrado no localStorage.");
        }

        // Cria uma consulta para buscar o documento onde o campo adAccountCode corresponde ao valor do token
        const q = query(
          collection(db, "users"),
          where("adAccountCode", "==", adAccountCode)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Pega o valor do campo 'looker' do primeiro documento correspondente
          const docData = querySnapshot.docs[0].data();
          if (docData.looker) {
            setLookerUrl(docData.looker); // Define a URL do Looker se o valor existir
          }
        } else {
          throw new Error("Documento não encontrado no Firestore.");
        }
      } catch (err) {
        console.error("Erro ao buscar o documento:", err.message);
        setError(err.message);
      }
    };

    fetchLookerUrl();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    <div>
      {/* Botão Flutuante - Exibe apenas se lookerUrl existir */}
      {lookerUrl && (
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          size="large"
          style={{
            position: "fixed",
            bottom: 24,
            right: 24,
            zIndex: 1000,
          }}
          onClick={showModal}
        />
      )}

      {/* Modal */}
      <Modal
        title="Relatório Looker"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1600} // Largura do modal, ajuste se necessário
      >
        {lookerUrl ? (
          <iframe
            src={lookerUrl}
            width="100%"
            height="800px"
            style={{ border: "none" }}
            title="Relatório Looker"
          ></iframe>
        ) : (
          <p>Carregando o relatório...</p>
        )}
      </Modal>
    </div>
  );
};

export default Gender;
