import React, { useState } from 'react';
import { DatePicker, Button, Dropdown, Menu } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const quickOptions = [
  { label: 'Mês passado', range: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')] },
  { label: 'Último trimestre', range: [moment().subtract(3, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')] },
  { label: 'Último semestre', range: [moment().subtract(6, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')] },
  { label: 'Este ano', range: [moment().startOf('year'), moment()] },
  { label: 'Ano passado', range: [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')] },
];

const DateRangeSelector = ({ onApply }) => {
  const [selectedRange, setSelectedRange] = useState(null);

  const handleQuickOptionClick = (range) => {
    setSelectedRange(range);
    onApply(range); // Dispara o intervalo da opção rápida
  };

  const handleDateChange = (dates) => {
    setSelectedRange(dates); // Atualiza o intervalo manualmente
  };

  const handleApply = () => {
    if (selectedRange) {
      onApply(selectedRange); // Dispara o intervalo manual
    }
  };

  const menu = (
    <Menu>
      {quickOptions.map((option, index) => (
        <Menu.Item key={index} onClick={() => handleQuickOptionClick(option.range)}>
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <Dropdown overlay={menu} placement="bottomLeft" arrow>
        <Button>Opções rápidas</Button>
      </Dropdown>
      <RangePicker
        onChange={handleDateChange}
        value={selectedRange}
        format="DD/MM/YYYY"
        style={{ width: '300px' }}
      />
      <Button type="primary" onClick={handleApply}>
        Aplicar
      </Button>
    </div>
  );
};

export default DateRangeSelector;
