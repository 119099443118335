import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { Card, notification, Statistic } from 'antd';
import CountUp from 'react-countup';

// Configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCboGZey8N5fTPjhzExQ2EdwnMxMcZkyKY",
  authDomain: "login-3159d.firebaseapp.com",
  projectId: "login-3159d",
  storageBucket: "login-3159d.firebasestorage.app",
  messagingSenderId: "503710927958",
  appId: "1:503710927958:web:df5506d167ce857722fff7"
};

// Inicializa o Firebase e o Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const MetaDashboard = () => {
  const [meta, setMeta] = useState(null);
  const [projectedSales, setProjectedSales] = useState(0);
  const [percentageAchieved, setPercentageAchieved] = useState(0); // Estado para a porcentagem alcançada
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const fetchMetaAndApiData = async () => {
      try {
        const adAccountCode = String(localStorage.getItem('token')).trim();
        if (!adAccountCode) {
          console.error("Token não encontrado no localStorage");
          return;
        }

        // Consulta o Firestore para buscar o valor `meta`
        const usersCollection = collection(db, "users");
        const q = query(usersCollection, where("adAccountCode", "==", adAccountCode));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data();
          setMeta(docData.meta);

          // Busca dados da API
          const response = await fetch(`https://ads-meta-867395045570.us-central1.run.app/api/insights/${adAccountCode}`);
          const data = await response.json();

          // Calcula projeção com os dados retornados (já filtrados pelo mês atual)
          const totalPurchaseValue = data.reduce((acc, item) => acc + parseFloat(item.purchase_value || 0), 0);

          // Número de dias até hoje no mês atual
          const currentDate = new Date();
          const daysSoFar = currentDate.getDate();

          // Total de dias no mês atual
          const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

          // Verifica se há dados suficientes para calcular a projeção
          if (daysSoFar > 0 && totalPurchaseValue > 0) {
            const monthlyProjection = (totalPurchaseValue / daysSoFar) * daysInMonth;
            setProjectedSales(monthlyProjection);

            // Calcula e define a porcentagem da meta alcançada
            const percentage = (monthlyProjection / docData.meta) * 100;
            setPercentageAchieved(percentage);

            // Verifica projeção em relação à meta
            checkProjectionAgainstMeta(docData.meta, monthlyProjection, percentage);
          }
        } else {
          console.log("Nenhum documento com o adAccountCode fornecido foi encontrado!");
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchMetaAndApiData();
  }, []);

  // Função para verificar a projeção e enviar notificação
  const checkProjectionAgainstMeta = (meta, projectedSales, percentage) => {
    if (!meta) return;

    const formattedPercentage = (
      <Statistic
        value={percentage}
        precision={2}
        suffix="%"
        valueStyle={{ color: 'black', fontSize: '24px' }}
        formatter={(value) => <CountUp end={value} duration={2} />}
      />
    );

    if (percentage < 50) {
      return;
    } else if (percentage >= 50 && percentage < 100) {
      api.open({
        message: 'Parabéns!',
        description: (
          <div>
            <p>Estamos quase lá! Continue assim para bater a meta!</p>
            <p>Meta Alcançada: {formattedPercentage}</p>
          </div>
        ),
      });
    } else if (percentage >= 100) {
      api.open({
        message: '🔥🔥🔥 Parabéns!',
        description: (
          <div>
            <p>Parabéns! Se continuarmos assim, vamos ultrapassar a meta!</p>
            <p>Meta Alcançada: {formattedPercentage}</p>
          </div>
        ),
      });
    }
  };

  const formatCurrency = (value) => {
    return `R$ ${parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const gradientBorderStyle = {
    background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
    padding: '1px',
    borderRadius: '10px',
    marginBottom: '10px',
  };

  return (
    <>
      {contextHolder}
      <div style={gradientBorderStyle}>
        <Card
          title="Projeção de Vendas vs Meta"
          headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
          bodyStyle={{ backgroundColor: '#1f1d39', padding: '0px' }}
          bordered={false}
          style={{ borderRadius: '8px', background: 'none', margin: '0' }}
        >
          <ResponsiveContainer width="100%" height={270}>
            <BarChart data={[{ name: 'Total', Meta: meta || 0, Projecao: projectedSales }]}>
              <Tooltip formatter={(value) => formatCurrency(value)} />

              <Bar dataKey="Projecao" stackId="a" fill="#f8b100" name="Projeção">
                <LabelList dataKey="Projecao" position="center" fill="#FFFFFF" formatter={(value) => formatCurrency(value)} />
              </Bar>
              <Bar dataKey="Meta" stackId="b" fill="#4a148c" name="Meta">
                <LabelList dataKey="Meta" position="center" fill="#FFFFFF" formatter={(value) => formatCurrency(value)} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </div>
    </>
  );
};

export default MetaDashboard;
